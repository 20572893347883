import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from '@rollbar/react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './base_client.scss';
import Navigation from './navigation/Navigation';
import { Provider } from 'react-redux'
import store from './redux/store';

import * as serviceWorker from './serviceWorker';

const rollbarConfig = {
  accessToken: '7efed2e0399440e0bd411ce5b619d3a9',
  environment: 'production',
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider config={rollbarConfig} store={store}>
    <ErrorBoundary>
      <Navigation />
    </ErrorBoundary>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
